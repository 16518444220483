<template>
  <div class="card">
    <h5 class="card-header">
      Desviación presupuestal
    </h5>
    <div class="card-body" style="height:280px">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Real</th>
              <th>Presupuesto</th>
              <th>Desciación</th>
            </tr>
          </thead>
          <tbody>
            <tr style="font-size: 15px;" >
              <td class="tamano sin-borde-top">
                <b>Ingreso</b>
              </td>
              <td class="sin-borde-top">
                $2,000.00
              </td>
              <td class="sin-borde-top">
                $1,980.00
              </td>
              <td class="sin-borde-top">
                $20.00
              </td>
            </tr>
            <br>
            <tr style="font-size: 15px;" >
              <td class="tamano sin-borde-top">
                <b>Gasto</b>
              </td>
              <td class="sin-borde-top">
                $2,000.00
              </td>
              <td class="sin-borde-top">
                $1,980.00
              </td>
              <td class="sin-borde-top">
                $20.00
              </td>
            </tr>
            <br>
            <tr style="font-size: 15px;" >
              <td class="tamano sin-borde-top">
                <b>Resultado</b>
              </td>
              <td class="sin-borde-top">
                $2,000.00
              </td>
              <td class="sin-borde-top">
                $1,980.00
              </td>
              <td class="sin-borde-top">
                $20.00
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.color-yellow {
  color: #F8AC59;
  font-size: 20px;
}

.color-red {
  color: #F78469;
}

.sin-borde-top {
  border-top: none;
}
</style>
