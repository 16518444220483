<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <router-link :to="{ name: 'tableroCompras' }">
            <div class="cantidad">
             123
            </div>
            <div class="text-danger text-center" style="font-size: 15px;">
              Requerimientos
            </div>
          </router-link>
        </div>
        <div class="activos col-lg-6">
          <router-link :to="{ name: 'tableroCompras' }">
            <div class="cantidad">
              123
            </div>
            <div class="text-warning text-center" style="font-size: 15px;">
              requerimientos pospuestos
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
 
}
</script>

<style scoped>
.cantidad {
  width: 100%;
  text-align: center;
  font-weight: 200;
  font-size: 23px;
}

.botonSoporte {
  font-size: 23px;
  color: #777;
}
</style>
