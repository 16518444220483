<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12">
          <router-link :to="{ name: 'tableroCompras' }">
            <div class="cantidad">
              12
            </div>
            <div class="text-danger text-center" style="font-size: 15px;">
              Cotizaciones
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.cantidad {
  width: 100%;
  text-align: center;
  font-weight: 200;
  font-size: 23px;
}

.botonSoporte {
  font-size: 23px;
  color: #777;
}
</style>