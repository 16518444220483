<template>
  <div class="card">
    <div class="card-header justify-content-between d-flex align-items-center">
      <h4 class="card-title">
        Gastos
      </h4>
      <div>
        <div class="btn-group">
          <button class="btn btn-light btn-sm">Semana</button>
          <button class="btn btn-light btn-sm">Mes</button>
          <button class="btn btn-light btn-sm">Año</button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <canvas id="gastos" height="80"></canvas>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      label: [],
      datos: []
    }
  },
  created() {
    var self = this

    // Actualización de la gráfica cuando se cambia el tamaño de la gráfica

    self.refrescarArticulos()

    window.addEventListener('resize', function(event){
      self.dibujarGrafica()
    })
  },
  methods: {
    dibujarGrafica(){
      var self = this

      var Data = {
          labels: ['Enero', 'febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
                label: "",
                backgroundColor: 'rgba(26,179,148,0.5)',
                borderColor: "rgba(26,179,148,1)",
                pointBackgroundColor: "rgba(26,179,148,1)",
                pointBorderColor: "#fff",
                borderWidth: 1,
                data: [65, 59, 80, 81, 56, 55, 9, 48, 68, 60, 45, 50]
            }
          ]
      };
      var Options = {
        responsive: true,
        legend: {
          //position: 'right',
          display: false
        }
      }
      
      var exc = document.getElementById('gastos').getContext("2d")

      new Chart(exc, {
        type: 'bar',
        data: Data,
        options:Options
      })
    },

    refrescarArticulos: function() {
      var self = this
 
      self.paramatros()
    },

    paramatros: function(){
      var self = this
      self.label= ['Router TPLink TL-WR840N','LiteBeam 5 AC Gen2','Pijas galvanizadas 1 1/2 hexagonal','Taquetes 3/8 plasticos','Grapas curvas para cable redondo 6mm','LiteBeam 5 AC Gen2']
      self.datos= [405,568,155,478,353,488]
      
      setTimeout(() => {
        self.dibujarGrafica()
      }, 100);
    }
  }
}
</script>

<style scoped>

</style>