<template>
  <div class="card">
    <h5 class="card-header">
      Articulos mas Comprados
    </h5>
    <div class="card-body">
      <canvas id="masCompras" height="158"></canvas>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      label: [],
      datos: []
    }
  },
  created() {
    var self = this

    // Actualización de la gráfica cuando se cambia el tamaño de la gráfica

    self.refrescarArticulos()

    window.addEventListener('resize', function(event){
      self.dibujarGrafica()
    })
  },
  methods: {
    dibujarGrafica(){
      var self = this

      var Data = {
          labels: self.label,
          datasets: [{
              data: self.datos,
              backgroundColor: [
                  'rgba(26,179,148,0.5)',
                  'rgba(181,184,207,10)',
                  'rgba(156,195,218,10)',
                  'rgba(222,222,222,10)',
                  'rgba(151,151,151,10)'
              ]
          }]
      };
      var Options = {
        responsive: true,
        legend: {
          position: 'right'
        }
      }
      
      var exc = document.getElementById('masCompras').getContext("2d")

      new Chart(exc, {
        type: 'pie',
        data: Data,
        options:Options
      })
    },

    refrescarArticulos: function() {
      var self = this
 
      self.paramatros()
    },

    paramatros: function(){
      var self = this
      self.label= ['Router TPLink TL-WR840N','LiteBeam 5 AC Gen2','Pijas galvanizadas 1 1/2 hexagonal','Taquetes 3/8 plasticos','Grapas curvas para cable redondo 6mm']
      self.datos= [405,568,155,478,353]
      
      setTimeout(() => {
        self.dibujarGrafica()
      }, 100);
    }
  }
}
</script>

<style scoped>

</style>
