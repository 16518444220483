<template>
  <Layout tituloPagina="Compras | Generales">
    <div class="row">
      <div class="col-lg-8">
        <cmp-secciones seccion="tablero"></cmp-secciones>
      </div>
      <div class="col-lg-4 text-right">
        <Router-link 
          :to="{
            name:'configuracionCompras'
          }"
          class="btn btn-link"
        >
          <b>
            <i class="fa fa-gears"></i> Configuración
          </b>
        </Router-link>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-lg-4">
        <WRequerimientos></WRequerimientos>
      </div>
      <div class="col-lg-2">
        <WCotizaciones></WCotizaciones>
      </div>
      <div class="col-lg-6">
        <WOrdenesCompra></WOrdenesCompra>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <WArticulosMasComprados></WArticulosMasComprados>
      </div>
      <div class="col-lg-4">
        <WDesviacionPresupuestal></WDesviacionPresupuestal>
      </div>
      <div class="col-lg-4">
        <WUltimasSolicitudesRequerimientos></WUltimasSolicitudesRequerimientos>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <WGastos></WGastos>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import CmpSecciones from '../CmpSecciones.vue'

import WRequerimientos from './WRequerimientos.vue'
import WCotizaciones from './WCotizaciones.vue'
import WOrdenesCompra from './WOrdenesCompra.vue'
import WArticulosMasComprados from './WArticulosMasComprados.vue'
import WDesviacionPresupuestal from './WDesviacionPresupuestal.vue'
import WUltimasSolicitudesRequerimientos from './WUltimasSolicitudesRequerimientos.vue'
import WGastos from './WGastos.vue'

export default {
  components: {
    Layout,
    CmpSecciones,
    WRequerimientos,
    WCotizaciones,
    WOrdenesCompra,
    WArticulosMasComprados,
    WDesviacionPresupuestal,
    WUltimasSolicitudesRequerimientos,
    WGastos
  },

  created: function () {
    var self = this
  }
}
</script>